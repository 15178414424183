import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();
import tooltip from "./directives/tooltip.js";
import "./directives/tooltip.css";
import { plugin, defaultConfig } from "@formkit/vue";
import "@formkit/themes/dist/genesis/theme.css";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtNT0fe4L7X17jt-EgffVUCZbQ3R9MXWM",
  authDomain: "plr-website.firebaseapp.com",
  projectId: "plr-website",
  storageBucket: "plr-website.appspot.com",
  messagingSenderId: "624283328417",
  appId: "1:624283328417:web:83630b9f1ece378aa735e0",
  measurementId: "G-TM0VCP81LT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const appVue = createApp(App);
appVue.component("font-awesome-icon", FontAwesomeIcon);
appVue.directive("tooltip", tooltip);
appVue.use(store).use(router).use(plugin, defaultConfig).mount("#app");
