
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
@Options({
  name: "NavigationMenu",
})
export default class NavigationMenu extends Vue {
  isPanelOpen = (): boolean => useStore().state.isNavOpen;

  closeNavigationMenuPanel(): void {
    this.$store.commit("toggleNav", false);
  }
}
