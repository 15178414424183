
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  name: "Burger",
})
export default class Burger extends Vue {
  isBurgerActive = (): boolean => useStore()?.state.isNavOpen;

  toggle(): void {
    this.$store.commit("toggleNav", !this.$store.state.isNavOpen);
  }
}
