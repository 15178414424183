import { createStore } from "vuex";

export default createStore({
  state: {
    isNavOpen: false,
    currentPageTitle: "",
  },
  getters: {},
  mutations: {
    toggleNav(state, payload) {
      state.isNavOpen = payload;
    },
    setCurrentPageTitle(state, payload) {
      state.currentPageTitle = payload;
    },
    initCurrentPageTitle(state, routerPosition) {
      switch (routerPosition) {
        case "about":
          state.currentPageTitle = "Présentation";
          break;
        case "projects":
          state.currentPageTitle = "Projets";
          break;
        case "skills":
          state.currentPageTitle = "Compétences";
          break;
        case "curiculum-vitae":
          state.currentPageTitle = "Curiculum Vitae";
          break;
        case "loisirs":
          state.currentPageTitle = "Loisirs";
          break;
        case "contact":
          state.currentPageTitle = "Contact";
          break;
        default:
          state.currentPageTitle = "Accueil";
          break;
      }
    },
  },
  actions: {},
  modules: {},
});
