
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Home",
  components: {
    // ...
  },
})
export default class Home extends Vue {
  previousBackgroundIndex = 0;
  // ...
  created(): void {
    this.$store.commit("setCurrentPageTitle", "Accueil");
  }
  goToPresentationPage(): void {
    document.getElementById("home-container")?.classList.remove("fade-in-up");
    document.getElementById("home-container")?.classList.add("fade-out-left");
    document
      .getElementById("home-container")
      ?.addEventListener("animationend", () => {
        document.getElementById("home-container")?.remove();
        this.$router.push("/about");
        this.$store.commit("setCurrentPageTitle", "Presentation");
      });
  }
  // change class background-1 from background-4 on home-container every three seconds
  mounted(): void {
    setInterval(() => {
      const homeContainer = document.getElementById("home-container");
      // create a random integer between 1 and 6
      const randomBackgroundIndex = Math.floor(Math.random() * 6) + 1;
      if (homeContainer) {
        homeContainer.classList.remove(
          "background-" + this.previousBackgroundIndex
        );
        homeContainer.classList.add("background-" + randomBackgroundIndex);
      }
      this.previousBackgroundIndex = randomBackgroundIndex;
    }, 5000);

    // remove hide and animate fade-in to h2 when clip-from-top-to-bottom is finished
    document
      .getElementById("home-container")
      ?.addEventListener("animationend", () => {
        document.getElementById("title-home")?.classList.remove("hide");
        document.getElementById("title-home")?.classList.add("first-text");
        document.getElementById("title-home")?.classList.add("fade-in");
      });
  }
}
