
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// @ts-nocheck
import { useStore } from "vuex";
import Burger from "./components/menu/Burger.vue";
import NavigationMenu from "./components/menu/NavigationMenu.vue";

export default {
  data() {
    return {
      refreshed: true,
      currentPageTitle: () => {
        if (this.refreshed) {
          this.$store.commit(
            "initCurrentPageTitle",
            window.location.pathname.slice(1)
          );
        }

        return useStore().state.currentPageTitle;
      },
    };
  },
  components: {
    Burger,
    NavigationMenu,
  },
  methods: {
    navigateTo(targetTitle: string): void {
      this.refreshed = false;
      this.$store.commit("toggleNav", false);
      this.$store.commit("setCurrentPageTitle", targetTitle);
    },
    goToAccueil(): void {
      this.refreshed = false;
      this.$router.push("/");
      this.$store.commit("setCurrentPageTitle", "Accueil");
    },
  },
};
